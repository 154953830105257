<template>
    <y-error404 />
</template>

<script>
    import YError404 from '@deps/Error404';

    export default {
        name: 'Error404',

        components: {
            YError404,
        },

        head: {
            /**
             * Set page title
             *
             * @returns {string}
             */
            title() {
                return {
                    inner: this.$t('errors.404.title'),
                };
            },
        },

        /**
         * @inheritDoc
         */
        mounted() {
            this.$wait.end('loading-page');
        },
    };
</script>
